@import '../functions';
@import '../variables';
@import '../mixins';

// register-login

.standalone{
	min-height: 100vh;
	display: flex;
	justify-content: center;
}

#register,
#connect{
	header{
		.logo{
			max-width: 150px;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: $spacer * 3;

			@include media-breakpoint-down(sm) {
				margin-bottom: $spacer * 2;
			}
		}
	}

	.content{

		@include media-breakpoint-down(sm) {

			h1{
				font-family: $font-family-script;
				margin-bottom: $spacer / 2;
			}

			h2{
				display: block;
				margin-bottom: $spacer * 2;
			}
		}

		hr{
			margin-left: 0;
			margin-right: 0;
		}
	}
}
